import Menu from "components/Menu";
import Footer from "../home/Footer";
import ScrollToTop from "helpers/ScrollToTop";
import { useEffect } from "react";

export default function TOS() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://app.termly.io/embed-policy.min.js";
    script.async = true;
    script.onload = () => {
      console.log("Termly script loaded successfully");
      console.log(document.querySelector("[name='termly-embed']")); // Log to check the iframe
    };
    script.onerror = () => console.error("Failed to load the Termly script");
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="bg-[white]">
      <ScrollToTop />
      <Menu type="landing" transparentTop />
      <div className="pt-[8rem] px-[1rem] max-w-[1000px] mx-auto">
        <h3 className="title uppercase md:text-[3rem] text-[2.25rem] font-[500] text-center mb-[4rem]">
          Terms and Conditions
        </h3>
        <div
          name="termly-embed"
          data-id="5a6c0072-5466-4bb7-80f1-4ee4499317bf"
          data-type="iframe"
        ></div>
      </div>
      <Footer />
    </div>
  );
}
